<template>
  <div>
    <div class="header">
      <div class="headerleft" @click="$router.go(-1)">
        <van-icon name="arrow-left" />
      </div>
      <div class="headercenter" @click="$router.push('/')">
        <img src="../assets/logo.png" />
      </div>
      <div class="headerright"></div>
    </div>

    <div class="loading" v-if="showloading">
      <van-loading type="spinner" color="#206C47" size="24px" vertical>
        加载中...
      </van-loading>
    </div>

    <div class="container" v-show="!showloading && newsDetail.Article_title">
      <div class="pagetitle">
        <div class="newsname">{{ newsDetail.Article_title }}</div>
        <div class="newstime">{{ newsDetail.add_date }}</div>
      </div>
      <div class="empty"></div>
      <div class="pagetext">
        <div class="contentTxt" v-html="newsDetail.content"></div>
      </div>
      <div class="empty"></div>
      <div
        class="product"
        v-if="newsDetail.pro_id != 0"
        @click="gotoDetail(newsDetail.pro_id)"
      >
        <div class="productImg"><img :src="newsDetail.pro_img" /></div>
        <div class="productMsg">
          <div class="productTitle">
            <div class="productName">{{ newsDetail.pro_name }}</div>
            <div class="productContent" v-html="newsDetail.pro_content"></div>
          </div>
          <div class="productDetail">
            <div>查看商品</div>
          </div>
        </div>
      </div>
      <div class="empty"></div>
    </div>

    <div
      class="contain"
      v-if="!showloading && !newsDetail.Article_title && notfound"
    >
      <div class="banner">
        <img class="bannerImg" src="../assets/404bgc.jpg" />
      </div>
      <div class="page">
        <img src="../assets/404.png" />
        <div class="en">EXCUSE ME</div>
        <div class="ch">很抱歉，您访问的页面不存在！</div>
        <div class="back" @click="$router.push('/')">返回首页</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon, Loading } from "vant";
import qs from "qs";

export default {
  components: {
    [Icon.name]: Icon,
    [Loading.name]: Loading,
  },
  data() {
    return {
      showloading: true,
      newsDetail: [], //新闻详情
      notfound: false,
    };
  },
  mounted() {
    this.getData(this.$route.query.id);
  },
  methods: {
    getData(id) {
      //   获取新闻详情
      let data1 = qs.stringify({
        id: id,
      });
      this.$axios
        .post(
          "https://mallxadmin.pyua.net/LKT/index.php?module=api&action=envelope&m=atc_index1",
          data1
        )
        .then((res) => {
          // console.log(res);
          this.showloading = false;
          if (res.status == 1) {
            this.newsDetail = res.article[0];
            document.title =
              this.newsDetail.Article_title + "-无邪资讯-无邪生物科技"; //资讯名称-无邪资讯-无邪生物科技
          } else {
            this.notfound = true;
          }
        });
    },

    // 跳转到商品详情页
    gotoDetail(id) {
      // console.log(id);
      this.$router.push({ path: `/productDetail?id=${id}` });
    },

    openShare() {
      let config = {
        link: window.location.href, // 网址，默认使用window.location.href
        // title: this.detail.course_data.name, // 标题，默认读取document.title
        imgUrl: this.goodsList.img_arr[0], // 图片, 默认取网页中第一个img标签
        types: ["wx", "wxline", "qq", "qzone", "sina"], // 启用的社交分享,默认为全部启用
        fnDoShare(type) {
          // console.log(type);
          // 执行分享的回调，type为'wx', 'wxline', 'qq', 'qzone', 'sina'
        },
      };
      let ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        Mshare.to("qq", {
          link: window.location.href,
          // title: this.detail.course_data.name,
          imgUrl: this.goodsList.img_arr[0],
        });
      } else {
        Mshare.popup(config);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  margin-top: 60%;
}

.header {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.75rem;
  background-color: #fff;
  padding: 0 1rem;
  border-bottom: 1px solid #ececec;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  .headerleft {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
  }
  .headercenter {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 1.6875rem;
      width: 2rem;
    }
  }
  .headerright {
    width: 1.5rem;
  }
}

.container {
  margin-top: 2.75rem;
  line-height: 1;
}

.pagetitle {
  padding: 1.875rem 1rem;
  .newsname {
    color: #206c47;
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 1rem;
    line-height: 1.5;
  }
  .newstime {
    color: #999;
    font-size: 0.75rem;
  }
}

.empty {
  background-color: #f5f5f5;
  height: 0.625rem;
  width: 100%;
}

.pagetext {
  padding: 1.875rem 1rem;
  .contentTxt {
    color: #333333;
    font-size: 0.875rem;
    line-height: 1.5;
    text-align: justify;
    img {
      width: 100%;
    }
  }
}

.product {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 1.875rem 1rem;
  .productImg {
    img {
      height: 6.125rem;
      width: auto;
    }
  }
  .productMsg {
    flex: 1;
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .productTitle {
      .productName {
        color: #206c47;
        font-size: 1rem;
      }
      .productContent {
        margin-top: 0.75rem;
        color: #333333;
        font-size: 0.75rem;
        line-height: 1.5;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .productDetail {
      display: flex;
      justify-content: flex-end;
      line-height: 1;
      color: #206c47;
      font-size: 0.75rem;
      div {
        border: 1px solid #206c47;
        padding: 0.5rem 1rem;
        border-radius: 1.25rem;
      }
    }
  }
}

.contain {
  width: 100%;
  // margin-top: 2.6875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .banner {
    width: 100%;
    font-size: 0;
    .bannerImg {
      width: 100%;
    }
  }
  .page {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #206c47;
    line-height: 1;
    img {
      height: 4.28125rem;
    }
    .en {
      font-size: 0.6875rem;
      margin: 2.0625rem 0 0.75rem 0;
    }
    .ch {
      font-size: 0.75rem;
    }
    .back {
      font-size: 0.75rem;
      border: 1px solid #206c47;
      padding: 0.625rem 1.5rem;
      border-radius: 1.875rem;
      margin-top: 1.375rem;
    }
  }
}
</style>